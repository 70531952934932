/// Generated by expo-google-fonts/generator
/// Do not edit by hand unless you know what you are doing
///

export { useFonts } from './useFonts';

export { default as __metadata__ } from './metadata.json';
export const LibreBaskerville_400Regular = require('./LibreBaskerville_400Regular.ttf');
export const LibreBaskerville_400Regular_Italic = require('./LibreBaskerville_400Regular_Italic.ttf');
export const LibreBaskerville_700Bold = require('./LibreBaskerville_700Bold.ttf');
